@import "./styles/colors";
@import "./styles/typographyMixins";



.App {
  text-align: center;


  .App-header {
    display: flex;
    flex-direction: row;
    background-color: $theme-header-background;
    @include header-text;

    @media (max-width: 800px) {
      .myName {
        font-size: 1.2rem;
      }
    }

  }

  .mainView {
    background-image: url("../public/images/sigmund-_dJCBtdUu74-unsplash.jpg");
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include ad-text;

    .roleHolder {
      display: flex;
      flex-direction: column;
      .role {
        opacity: 50%;

        animation: pulse 4s infinite;

        &.role1 {
          animation-delay: 6s;
        }
        &.role2 {
          animation-delay: 3s;
        }
        &.role3 {
          animation-delay: 0s;
        }
      }
    }
  }

  section {
    padding-top: 70px;
    margin-top: -70px;
  }

  .cardHolder {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: grid;
    height: 100%;
    @media (min-width: 1000px) {
      grid-template-columns: 30% 30%;
    }
    @media (max-width: 1000px) {
      grid-template-columns: 60%;
      .MuiCardContent-root {
        .MuiTypography-root {
          font-size: 2rem;
        }
      }
    }
    grid-row-gap: 20px;
    grid-column-gap: 20px;

    .card {
      border-style: solid;
      border-color: $blue-gray-700;
      border-width: 1px;
      background-color: $blue-gray-400;
      height: 100%;
    }
  }

  .contactForm {
    display: grid;
    @media (max-width: 1000px) {
      grid-template-columns: 80%;
    }
    @media (min-width: 1000px) {
      grid-template-columns: 40%;
    }
    justify-content: center;
    margin-top: 0px;
    .MuiTextField-root {
      margin-bottom: 8px;
    }
  }

}

@keyframes pulse {
  0% { opacity: 0.3; }
  50% { opacity: 1.0; }
  100% { opacity: 0.3; }
}