@import "colors";

@mixin header-text() {
  * {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: $theme-light;
  }
}

@mixin ad-text() {
  * {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: $theme-light;
    font-size: xxx-large;
  }
}

